<template>
  <div>
      <vs-dropdown :disabled="disabled" vs-custom-content  ><!-- vs-trigger-click -->
          <div>
              <div v-if="isLabelOkay" class="con-select" style="text-align:left">
                  <label class="vs-select--label">{{LabelText}}</label>
              </div>
              <div style="text-align:left">
                  <a radius class="a-icon bg-light p-1" href.prevent>
                      <span >{{Title}}</span>
                      <vs-icon class="" icon="expand_more"></vs-icon>
                  </a>
              </div>
          </div>
          <vs-dropdown-menu class="loginx">
            <div style="max-width:500px">
              <vs-tabs >
                  <vs-tab :label="Title">
                    <span>{{$t('DNDMULTISELECT.CLICK_TO_SELECT')}}</span>
                    <div class="clearfix mt-2" >
                    <template v-for="(src, index) in this.original" >
                        <div :key="'multiselect_'+index" class="float-left modelx cursor-pointer" @click="add(src)" @mouseover="setColor(src.id,'primary')" @mouseleave="setColor(src.id,'')">
                          <vs-chip :color="getColor(src.id)">{{ src.txt }}</vs-chip>
                        </div>
                    </template>
                    <vs-divider position="center">{{$t('DNDMULTISELECT.CHOSEN')}}</vs-divider>
                    <template v-for="(sel, index) in this.selected" >
                        <div :key="'multiselect_sel_'+index" class="float-left modelx cursor-pointer" @click="remove(sel)"  @mouseover="setColor(sel.id,'primary')" @mouseleave="setColor(sel.id,'')">
                          <vs-chip :color="getColor(sel.id)">{{ sel.txt }}</vs-chip></div>
                    </template>
                    </div>
                  </vs-tab>
              </vs-tabs>
            </div>
          </vs-dropdown-menu>
      </vs-dropdown>
  </div>
</template>
<script>

export default {
  name: "DndMultiSelect",
  components:{
    },
  props:['value','disabled','isLabelOkay','Source','Title','LabelText'],
   data: function (){
     return {
       selected:this.value,
       original:this.Source,
       colors:[]
     }
  },
  computed:{

  },
  methods:{
    getColor(id){
      var ret = "";
      for(var i=0; i < this.colors.length; i++)
      {
        if(this.colors[i].id == id)
        {
          ret = this.colors[i].color;
          break;
        }
      }
      return ret;
    },
    setColor(id,color){
      var found = false;
      for(var i=0; i < this.colors.length; i++)
      {
        if(this.colors[i].id == id)
        {
          found = true;
          this.colors[i].color = color;
          break;
        }
      }
      if(found == false)
      {
        var item = {id:id,color:color};
        this.colors.push(item);
      }
    },
    add(src){
      this.selected.push(src);
      for(var i = 0; i < this.original.length; i++)
      {
        if(this.original[i].id == src.id)
        {
          this.setColor(this.original[i].id,'');
          this.original.splice(i,1);
          break;
        }
      }
    },
    remove(sel)
    {
      this.original.push(sel);

      for(var i = 0; i < this.selected.length; i++)
      {
        if(this.selected[i].id == sel.id)
        {
          this.setColor(this.selected[i].id,'primary');
          this.selected.splice(i,1);
          break;
        }
      }
    }
  },
  watch:{
    selected(value){
      this.$emit('input', value);
    }
  }
};
</script>
