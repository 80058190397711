<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('PRODGROUP.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PRODGROUP.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="12">
      <vs-card v-show="editMode" >
        <div id="editProductgroupCard" class="vs-con-loading__container">
          <h3 v-show="!newProductgroup">{{$t('PRODGROUP.EDIT.HEADER')}}</h3>
          <h3 v-show="newProductgroup">{{$t('PRODGROUP.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('PRODGROUP.EDIT.LABEL.GROUPNAME')"  class="inputx" :placeholder="$t('PRODGROUP.EDIT.PLACEHOLDER.GROUPNAME')"  v-model="selected.groupname" />
            <vs-input :label="$t('PRODGROUP.EDIT.LABEL.KUERZEL')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.EDIT.PLACEHOLDER.KUERZEL')"  v-model="selected.kuerzel" />
            <vs-input :label="$t('PRODGROUP.EDIT.LABEL.DESCRIPTION')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.EDIT.PLACEHOLDER.DESCRIPTION')"  v-model="selected.description" />
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = !productgroupDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card v-show="editModeCat" >
        <div id="editProductcategoryCard" class="vs-con-loading__container">
          <h3 v-show="!newProductcategory">{{$t('PRODGROUP.CAT.EDIT.HEADER')}}</h3>
          <h3 v-show="newProductcategory">{{$t('PRODGROUP.CAT.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('PRODGROUP.CAT.EDIT.LABEL.GROUPNAME')"  class="inputx" :placeholder="$t('PRODGROUP.CAT.EDIT.PLACEHOLDER.GROUPNAME')"  v-model="selectedCat.name" />
            <vs-input :label="$t('PRODGROUP.CAT.EDIT.LABEL.KUERZEL')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.CAT.EDIT.PLACEHOLDER.KUERZEL')"  v-model="selectedCat.kuerzel" />
            <vs-input :label="$t('PRODGROUP.CAT.EDIT.LABEL.DESCRIPTION')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.CAT.EDIT.PLACEHOLDER.DESCRIPTION')"  v-model="selectedCat.description" />
            <vs-input-number v-model="selectedCat.printerOrder" :label="$t('PRODGROUP.CAT.EDIT.LABEL.ORDER')" class="inputx ml-3" />
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = !productcategoryDataChanged v-on:SaveClicked="SaveCat" v-on:CancelClicked="CancelCat"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card v-show="editModeVariant" >
        <div id="editProductvariantCard" class="vs-con-loading__container">
          <h3 v-show="!newProductvariant">{{$t('PRODGROUP.VAR.EDIT.HEADER')}}</h3>
          <h3 v-show="newProductvariant">{{$t('PRODGROUP.VAR.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('PRODGROUP.VAR.EDIT.LABEL.BEZ')"  class="inputx" :placeholder="$t('PRODGROUP.VAR.EDIT.PLACEHOLDER.BEZ')"  v-model="selectedVariant.bez" />
            <vs-input :label="$t('PRODGROUP.VAR.EDIT.LABEL.SHORT')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.VAR.EDIT.PLACEHOLDER.SHORT')"  v-model="selectedVariant.label_short" />
            <vs-input :label="$t('PRODGROUP.VAR.EDIT.LABEL.LONG')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.VAR.EDIT.PLACEHOLDER.LONG')"  v-model="selectedVariant.label_long" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-input :label="$t('PRODGROUP.VAR.EDIT.LABEL.DESCRIPTION')"  class="inputx" :placeholder="$t('PRODGROUP.VAR.EDIT.PLACEHOLDER.DESCRIPTION')"  v-model="selectedVariant.description" />
            <vs-select :label="$t('PRODGROUP.VAR.EDIT.LABEL.PROD_GRP')" class="ml-3 " v-model="selectedVariant.produkt_group_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in productgrps" />
            </vs-select>
            <vs-select  class="ml-3" v-model="selectedVariant.extraoption" :label="$t('PRODGROUP.VAR.EDIT.LABEL.EXTRA_OPTION')">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in extraoptions" />
            </vs-select>
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = !productvariantDataChanged v-on:SaveClicked="SaveVariant" v-on:CancelClicked="CancelVariant"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card v-show="editModeVariantGroup" >
        <div id="editProductvariantGroupCard" class="vs-con-loading__container">
          <h3 v-show="!newProductvariantGroup">{{$t('PRODGROUP.VAR_GROUP.EDIT.HEADER')}}</h3>
          <h3 v-show="newProductvariantGroup">{{$t('PRODGROUP.VAR_GROUP.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('PRODGROUP.VAR_GROUP.EDIT.LABEL.BEZ')"  class="inputx" :placeholder="$t('PRODGROUP.VAR_GROUP.EDIT.PLACEHOLDER.BEZ')"  v-model="selectedVariantGroup.bez" />
            <vs-input :label="$t('PRODGROUP.VAR_GROUP.EDIT.LABEL.LABEL')"  class="inputx ml-3" :placeholder="$t('PRODGROUP.VAR_GROUP.EDIT.PLACEHOLDER.LABEL')"  v-model="selectedVariantGroup.label" />
            <vs-input-number v-model="selectedVariantGroup.order" :label="$t('PRODGROUP.VAR_GROUP.EDIT.LABEL.ORDER')" class="inputx ml-3" />
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = !productvariantgroupDataChanged v-on:SaveClicked="SaveVariantGroup" v-on:CancelClicked="CancelVariantGroup"></SaveCancel>   
        </div>
      </vs-card>      
      <vs-card >

        <vs-tabs v-model="activeTab">
          <vs-tab :label="$t('PRODGROUP.GROUPS')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddProductgroup">{{$t('PRODGROUP.ADD')}}</vs-button>
            </div> 
            <div id="productgroup_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="productgroups"
                :noDataText="$t('PRODGROUP.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRODGROUP.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="groupname">
                    {{$t('PRODGROUP.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="kuerzel">
                    {{$t('PRODGROUP.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="description">
                    {{$t('PRODGROUP.TABLE.COL3')}}
                  </vs-th>                                                      
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="'grp_'+indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.groupname">
                      {{tr.groupname}}
                    </vs-td>      
                    <vs-td :data="tr.kuerzel">
                      {{tr.kuerzel}}
                    </vs-td> 
                    <vs-td :data="tr.description">
                      {{tr.description}}
                    </vs-td>                                                           
                    <vs-td>
                      <vs-button  @click="EditProductgroup(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteProductgroup(tr.id,tr.groupname)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>   
          </vs-tab>
          <vs-tab :label="$t('PRODGROUP.CATEGORIES')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddProductcategory">{{$t('PRODGROUP.CAT.ADD')}}</vs-button>
            </div> 
            <div id="productcategory_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="productcategories"
                :noDataText="$t('PRODGROUP.CAT.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRODGROUP.CAT.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('PRODGROUP.CAT.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="kuerzel">
                    {{$t('PRODGROUP.CAT.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="description">
                    {{$t('PRODGROUP.CAT.TABLE.COL3')}}
                  </vs-th> 
                  <vs-th sort-key="printerOrder">
                    {{$t('PRODGROUP.CAT.TABLE.COL4')}}
                  </vs-th>  
                                                                     
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="'table_cat_'+indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>      
                    <vs-td :data="tr.kuerzel">
                      {{tr.kuerzel}}
                    </vs-td> 
                    <vs-td :data="tr.description">
                      {{tr.description}}
                    </vs-td>  
                    <vs-td :data="tr.printerOrder">
                      {{tr.printerOrder}}
                    </vs-td>                                                       
                    <vs-td>
                      <vs-button  @click="EditProductCategory(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteProductCategory(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div> 

            <div class="clearfix">
              <template v-for="(cat,idx) in dndmember">
                <div :key="'cats_'+idx" class="float-left mr-3" style="max-width:300px"> 
                  <vs-card>
                    <div slot="header">
                      <h4 class="font-weight-bold card-title d-flex">{{cat.name}}
                          <div class="ml-auto text-right">
                            <vs-button @click="SaveMember(cat.id)" class="ml-2" size="small" color="primary" type="filled" icon="save"></vs-button>  
                          </div>
                      </h4>
                    </div>
                    <div class="d-flex mb-2">
                      <DndMultiSelect :Source="productsForDnd" v-model="cat.member" :Title="$t('PRODGROUP.CAT.SEL_PROD')" ></DndMultiSelect>
                    </div>
                    <div class="clearfix">
                      <template v-for="mem in cat.member">
                        <div :key="'memid_'+mem.id" class="float-left"><vs-chip>{{mem.txt}}</vs-chip></div>
                      </template>
                    </div>
                  </vs-card>
                </div>
              </template>
            </div>

          </vs-tab>
          <vs-tab :label="$t('PRODGROUP.PRODUCTVARIANTS')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddProductvariant">{{$t('PRODGROUP.VAR.ADD')}}</vs-button>
            </div> 
            <div id="productvariant_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="procuctvariants"
                :noDataText="$t('PRODGROUP.VAR.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRODGROUP.VAR.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PRODGROUP.VAR.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="label_short">
                    {{$t('PRODGROUP.VAR.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="label_long">
                    {{$t('PRODGROUP.VAR.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="produkt_group_fk">
                    {{$t('PRODGROUP.VAR.TABLE.COL4')}}
                  </vs-th>   
                  <vs-th sort-key="extraoption">
                    {{$t('PRODGROUP.VAR.TABLE.COL5')}}
                  </vs-th>                                                                      
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="'table_var_'+indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>      
                    <vs-td :data="tr.label_short">
                      {{tr.label_short}}
                    </vs-td> 
                    <vs-td :data="tr.label_long">
                      {{tr.label_long}}
                    </vs-td>
                    <vs-td :data="tr.produkt_group_fk">
                      {{GetNameOfProductGroup(tr.produkt_group_fk)}}
                    </vs-td>    
                    <vs-td :data="tr.extraoption">
                      {{GetProductVariantExtraOptionsText(tr.extraoption)}}
                    </vs-td>                                                                           
                    <vs-td>
                      <vs-button  @click="EditProductVariant(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteProductvariant(tr.id,tr.bez)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div> 
          </vs-tab>    
          <vs-tab :label="$t('PRODGROUP.PRODUCTVARIANTGROUPS')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddProductvariantGroup">{{$t('PRODGROUP.VAR_GROUP.ADD')}}</vs-button>
            </div> 
            <div id="productvariantgroup_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="procuctvariantgroups"
                :noDataText="$t('PRODGROUP.VAR_GROUP.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRODGROUP.VAR_GROUP.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PRODGROUP.VAR_GROUP.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="label">
                    {{$t('PRODGROUP.VAR_GROUP.TABLE.COL2')}}
                  </vs-th>                                                     
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="'table_var_'+indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>      
                    <vs-td :data="tr.label">
                      {{tr.label}}
                    </vs-td>                                                           
                    <vs-td>
                      <vs-button  @click="EditProductVariantGroup(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteProductvariantGroup(tr.id,tr.bez)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div> 
            <div class="clearfix">
              <template v-for="(grp,idx) in dndVariantGroups">
                <div :key="'cats_'+idx" class="float-left mr-3" style="max-width:300px"> 
                  <vs-card>
                    <div slot="header">
                      <h4 class="font-weight-bold card-title d-flex">{{grp.bez}}
                          <div class="ml-auto text-right">
                            <vs-button @click="SaveVariantGroupMember(grp.id)" class="ml-2" size="small" color="primary" type="filled" icon="save"></vs-button>  
                          </div>
                      </h4>
                    </div>
                    <div class="d-flex mb-2">
                      <DndMultiSelect :Source="variantsForDnd" v-model="grp.member" :Title="$t('PRODGROUP.VAR_GROUP.SEL_VAR')" ></DndMultiSelect>
                    </div>
                    <div class="clearfix">
                      <template v-for="mem in grp.member">
                        <div :key="'memid_'+mem.id" class="float-left"><vs-chip>{{mem.txt}}</vs-chip></div>
                      </template>
                    </div>
                  </vs-card>
                </div>
              </template>
            </div>
          </vs-tab>                 
        </vs-tabs>      
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import DndMultiSelect from './components/DndMultiSelect.vue';

export default {
  name: "Productgroups",
  components: {
    SaveCancel,
    DndMultiSelect
  },
    data: function (){
      return {
      productgroups:[],
      productcategories:[],
      procuctvariants:[],
      procuctvariantgroups:[],
      selected:[],
      selectedOld:"",
      selectedCat:[],
      selectedOldCat:"", 
      selectedVariant:[],
      selectedOldVariant:"", 
      selectedOldVariantGroup:"",           
      selectedVariantGroup:[],
      editMode:false,
      newProductgroup:false,
      newProductcategory:false,
      newProductvariant:false,
      newProductvariantGroup:false,
      editModeCat:false,
      editModeVariant:false,
      editModeVariantGroup:false,
      products:[],
      dndmember:[],
      dndVariantGroups:[],
      activeTab:0
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.productgroup.productgroups.data != null)
      {
        data = this.$store.state.productgroup.productgroups.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.productgroup.productgroups.status) != 'undefined')
        loading = this.$store.state.productgroup.productgroups.status.loading;
      return loading;
    },
    getTableDataCat() {
      var data = [];
      if(this.$store.state.productcategory.productcategories.data != null)
      {
        data = this.$store.state.productcategory.productcategories.data
      }
      return data;
    },
    getTableDataVariant() {
      var data = [];
      if(this.$store.state.productvariant.productvariants.data != null)
      {
        data = this.$store.state.productvariant.productvariants.data
      }
      return data;
    },
    getTableDataVariantGroup() {
      var data = [];
      if(this.$store.state.productvariant.productvariantgroups.data != null)
      {
        data = this.$store.state.productvariant.productvariantgroups.data
      }
      return data;
    },
    loadingTableDataCat () {
      var loading = false;
      if(typeof(this.$store.state.productcategory.productcategories.status) != 'undefined')
        loading = this.$store.state.productcategory.productcategories.status.loading;
      return loading;
    }, 
    loadingTableDataVariant () {
      var loading = false;
      if(typeof(this.$store.state.productvariant.productvariants.status) != 'undefined')
        loading = this.$store.state.productvariant.productvariants.status.loading;
      return loading;
    },  
    loadingTableDataVariantGroup () {
      var loading = false;
      if(typeof(this.$store.state.productvariant.productvariantgroups.status) != 'undefined')
        loading = this.$store.state.productvariant.productvariantgroups.status.loading;
      return loading;
    }, 
    productgroupDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    },
    productcategoryDataChanged(){
        return JSON.stringify(this.selectedCat) != this.selectedOldCat;
    },
    productvariantDataChanged(){
        return JSON.stringify(this.selectedVariant) != this.selectedOldVariant;
    },
    productvariantgroupDataChanged(){
        return JSON.stringify(this.selectedVariantGroup) != this.selectedOldVariantGroup;
    },
    extraoptions(){
      return this.GetProductVariantExtraOptionsForSelect();
    },
    allProducts() {
      var data = [];
      if(this.$store.state.products.productAdminList.data != null)
      {
        data = this.$store.state.products.productAdminList.data;
      }
      return data;
    },
    productsForDnd(){
      var prod = [];

      for(var i = 0; i <  this.allProducts.length; i++)
      {
        var el = {id:this.allProducts[i].id,txt:this.allProducts[i].bez};
        prod.push(el);
      }

      return prod;
    },
    variantsForDnd(){
      var prod = [];

      if(this.$store.state.productvariant.productvariants.data != null)
      {
        var data = this.$store.state.productvariant.productvariants.data;
        data.forEach(element => {
           var el = {id:element.id,txt:element.bez + ' ('+element.label_short+')'};
          prod.push(el);
        });
      }
      return prod;
    },
    
    categoriesWithExendedMember(){
      var ret = [];

      this.productcategories.forEach(element => {
        var tmp = JSON.stringify(element);
        var cat = JSON.parse(tmp);
        cat.member = [];

        for(var i = 0; i < element.member.length; i++)
        {
          var mem = {id:element.member[i].product_fk,txt:this.GetProductBez(element.member[i].product_fk).bez}
          cat.member.push(mem);
        }
        ret.push(cat);
      });

      return ret;
    },
    variantgroupsWithExendedMember(){
      var ret = [];

      this.procuctvariantgroups.forEach(element => {
        var tmp = JSON.stringify(element);
        var cat = JSON.parse(tmp);
        cat.member = [];

        for(var i = 0; i < element.member.length; i++)
        {
          var mem = {id:element.member[i].product_variant_fk,txt:this.GetProductVariantBez(element.member[i].product_variant_fk).bez}
          cat.member.push(mem);
        }
        ret.push(cat);
      });

      return ret;
    },
    productgrps(){
      var ret = [];
      ret.push({"text": "- - - - -", "value":-1});
      
      if(this.$store.state.productgroup.productgroups.data != null)
      {
        for (let i = 0; i < this.$store.state.productgroup.productgroups.data.length; i++)
        {
            var val = {"text": this.$store.state.productgroup.productgroups.data[i].groupname + " ("+this.$store.state.productgroup.productgroups.data[i].kuerzel+")", "value":this.$store.state.productgroup.productgroups.data[i].id};
            ret.push(val);
        }        
      }
      return ret;
    }
  },
  watch: {
      categoriesWithExendedMember(value){
          this.dndmember = value;
      },
      variantgroupsWithExendedMember(value){
          this.dndVariantGroups = value;
      },
      allProducts(value) {
          this.products = value;
      },
      getTableData(value) {
          this.productgroups = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#productgroup_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#productgroup_table'});
        }
      },
      getTableDataCat(value) {
          this.productcategories = value;
      },
      loadingTableDataCat(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#productcategory_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#productcategory_table'});
        }
      },
      getTableDataVariant(value) {
          this.procuctvariants = value;
      },
      loadingTableDataVariant(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#productvariant_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#productvariant_table'});
        }
      },
      getTableDataVariantGroup(value) {
          this.procuctvariantgroups = value;
      },
      loadingTableDataVariantGroup(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#productvariantgroup_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#productvariantgroup_table'});
        }
      }
      
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      LoadData: function ()
      {
          this.$store.dispatch('productgroup/getProductgroups'); 
          this.$store.dispatch('productcategory/getProductcategories'); 
          this.$store.dispatch('products/getProductAdminList'); 
          this.$store.dispatch('productvariant/getProductvariants'); 
          this.$store.dispatch('productvariant/getProductvariantGroups'); 
      },
      GetNameOfProductGroup(id)
      {
        var ret = " - ";
        if(this.$store.state.productgroup.productgroups.data != null)
        {
          for (let i = 0; i < this.$store.state.productgroup.productgroups.data.length; i++)
          {
            if(this.$store.state.productgroup.productgroups.data[i].id == id)
            {
              ret = this.$store.state.productgroup.productgroups.data[i].groupname + " ("+this.$store.state.productgroup.productgroups.data[i].kuerzel+")";
              break;
            }
          }        
        }
        return ret;
      },
      EditProductgroup(id){
        if(this.$store.state.productgroup.productgroups.data != null)
        {
          for(var i = 0; i < this.$store.state.productgroup.productgroups.data.length; i++)
          {
            if(this.$store.state.productgroup.productgroups.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.productgroup.productgroups.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      EditProductCategory(id){
        if(this.$store.state.productcategory.productcategories.data != null)
        {
          for(var i = 0; i < this.$store.state.productcategory.productcategories.data.length; i++)
          {
            if(this.$store.state.productcategory.productcategories.data[i].id==id)
            {
              this.selectedOldCat = JSON.stringify(this.$store.state.productcategory.productcategories.data[i]);
              this.selectedCat = JSON.parse(this.selectedOldCat);
              this.editModeCat = true;
              break;
            }
          }
        }
      },  
      EditProductVariant(id){
        if(this.$store.state.productvariant.productvariants.data != null)
        {
          for(var i = 0; i < this.$store.state.productvariant.productvariants.data.length; i++)
          {
            if(this.$store.state.productvariant.productvariants.data[i].id==id)
            {
              this.selectedOldVariant = JSON.stringify(this.$store.state.productvariant.productvariants.data[i]);
              this.selectedVariant = JSON.parse(this.selectedOldVariant);
              this.editModeVariant = true;
              break;
            }
          }
        }
      },   
      EditProductVariantGroup(id){
        if(this.$store.state.productvariant.productvariantgroups.data != null)
        {
          for(var i = 0; i < this.$store.state.productvariant.productvariantgroups.data.length; i++)
          {
            if(this.$store.state.productvariant.productvariantgroups.data[i].id==id)
            {
              this.selectedOldVariantGroup = JSON.stringify(this.$store.state.productvariant.productvariantgroups.data[i]);
              this.selectedVariantGroup = JSON.parse(this.selectedOldVariantGroup);
              this.editModeVariantGroup = true;
              break;
            }
          }
        }
      }, 
      AddProductgroup(){
        this.selected={id:0,groupname:"",kuerzel:"",description:""};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
        this.newProductgroup = true;
      },
      AddProductcategory(){
        this.selectedCat={id:0,name:"",kuerzel:"",description:"",printerOrder:0};
        this.selectedOldCat = JSON.stringify(this.selectedCat);
        this.editModeCat = true;
        this.newProductcategory = true;
      }, 
      AddProductvariant(){
        this.selectedVariant={id:0,bez:"",kuerzel:"",description:"",label_long:"",label_short:"",company_fk:0,produkt_group_fk:-1,extraoption:-1};
        this.selectedOldVariant = JSON.stringify(this.selectedVariant);
        this.editModeVariant = true;
        this.newProductvariant = true;
      },  
      AddProductvariantGroup(){
        this.selectedVariantGroup={id:0,bez:"",label:"",company_fk:0,order:0};
        this.selectedOldVariantGroup = JSON.stringify(this.selectedVariantGroup);
        this.editModeVariantGroup = true;
        this.newProductvariantGroup= true;
      },  
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editProductgroupCard'});

        var name = this.selected.name;
            this.$store.dispatch('productgroup/saveProductgroup', this.selected)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.SUCCESS.SAVE').replace("*1*",name)});
                  this.editMode = false;
                }
                else
                {
                  var text = this.$t('PRODGROUP.ERROR.SAVE').replace("*1*",name);
                  text = text.replace("*2*",this.$t(response.text));
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductgroupCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.ERROR.SAVE').replace("*1*",name)+' '+error});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductgroupCard'});
              });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newProductgroup = false;
      }, 
      SaveCat(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editProductcategoryCard'});

        var name = this.selectedCat.name;
            this.$store.dispatch('productcategory/saveProductcategory', this.selectedCat)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.CAT.SUCCESS.SAVE').replace("*1*",name)});
                  this.editModeCat = false;
                }
                else
                {
                  var text = this.$t('PRODGROUP.CAT.ERROR.SAVE').replace("*1*",name);
                  text = text.replace("*2*",this.$t(response.text));
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductcategoryCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.CAT.ERROR.SAVE').replace("*1*",name).replace("*2*",error)});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductcategoryCard'});
              });        
      },
      CancelCat(){
        this.$data.selectedCat = [];
        this.editModeCat = false;
        this.newProductcategory = false;
      },  
      SaveVariant(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editProductvariantCard'});

        var name = this.selectedVariant.bez;
            this.$store.dispatch('productvariant/saveProductvariant', this.selectedVariant)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.VAR.SUCCESS.SAVE').replace("*1*",name)});
                  this.editModeVariant = false;
                }
                else
                {
                  var text = this.$t('PRODGROUP.VAR.WARNING.SAVE').replace("*1*",name);
                  text = text.replace("*2*",this.$t(response.text));
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductvariantCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.VAR.ERROR.SAVE').replace("*1*",name)+' '+error});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductvariantCard'});
              });        
      },
      CancelVariant(){
        this.$data.selectedVariant = [];
        this.editModeVariant = false;
        this.newProductvariant = false;
      }, 
      SaveVariantGroup(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editProductvariantGroupCard'});

        var name = this.selectedVariantGroup.bez;
            this.$store.dispatch('productvariant/saveProductvariantGroup', this.selectedVariantGroup)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.VAR_GROUP.SUCCESS.SAVE').replace("*1*",name)});
                  this.editModeVariantGroup = false;
                }
                else
                {
                  var text = this.$t('PRODGROUP.VAR_GROUP.WARNING.SAVE').replace("*1*",name);
                  text = text.replace("*2*",this.$t(response.text));
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductvariantGroupCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.VAR_GROUP.ERROR.SAVE').replace("*1*",name)+' '+error});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductvariantGroupCard'});
              });        
      },
      CancelVariantGroup(){
        this.$data.selectedVariantGroup = [];
        this.editModeVariantGroup = false;
        this.newProductvariantGroup = false;
      },                  
      OpenConfirmDeleteProductgroup(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRODGROUP.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      OpenConfirmDeleteProductcategory(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRODGROUP.CAT.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteCatRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      OpenConfirmDeleteProductvariant(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRODGROUP.VAR.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteVarRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      OpenConfirmDeleteProductvariantGroup(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRODGROUP.VAR_GROUP.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteVarGroupRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('productgroup/deleteProductgroup', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('PRODGROUP.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      SendDeleteCatRequest: function(parameters)
      {
        this.$store.dispatch('productcategory/deleteProductcategory', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.CAT.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('PRODGROUP.CAT.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.CAT.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      SendDeleteVarRequest: function(parameters)
      {
        this.$store.dispatch('productvariant/deleteProductvariant', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.VAR.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('PRODGROUP.VAR.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.VAR.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      SendDeleteVarGroupRequest: function(parameters)
      {
        this.$store.dispatch('productvariant/deleteProductvariantGroup', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.VAR_GROUP.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('PRODGROUP.VAR_GROUP.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.VAR_GROUP.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      Reordered(props)
      {
        // alert(props.from+" "+props.to);
        // https://github.com/rlemaigre/Easy-DnD

        // Änderung (from -> to) auf das member array anwenden
        props.apply(this.members);
      },
      onInsert(event)
      {
        alert(JSON.stringify(event));
      },
      GetProductBez(prod_fk){
          var retVal = {bez:"",article_nr:""};

          for(var i = 0; i < this.products.length; i++)
          {
            if(this.products[i].id == prod_fk)
            {
              retVal.bez = this.products[i].bez;
              retVal.article_nr = this.products[i].article_nr;
              break;
            }
          }
          
          return retVal;
      },
      GetProductVariantBez(id){
        var retVal = {bez:""};

          for(var i = 0; i < this.procuctvariants.length; i++)
          {
            if(this.procuctvariants[i].id == id)
            {
              retVal.bez = this.procuctvariants[i].bez;
              break;
            }
          }
          
          return retVal;
      },
      SaveMember(id){
        for(var i = 0; i < this.dndmember.length; i++)
        {
          if(this.dndmember[i].id == id)
          {
            var items = [];

            this.dndmember[i].member.forEach(mem => {
              items.push({id:0,product_fk:mem.id,productCategory_fk:this.dndmember[i].id});
            });


            this.$store.dispatch('productcategory/saveMembersForProductcategory', items)
            .then(response => {
              if(response.success == true)
              {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.CATMEM.SUCCESS.SAVE').replace("*1*",this.dndmember[i].name)});  
              }
              else
              {
                var text = this.$t('PRODGROUP.CATMEM.WARNING.SAVE').replace("*1*",this.$t(response.text));
                this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
              }
            }, 
            error =>{

                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.CATMEM.ERROR.SAVE').replace("*1*",error)});  
            });

            break;
          }
        }
      },
      SaveVariantGroupMember(id){
        for(var i = 0; i < this.dndVariantGroups.length; i++)
        {
          if(this.dndVariantGroups[i].id == id)
          {
            var items = [];

            this.dndVariantGroups[i].member.forEach(mem => {
              items.push({id:0,product_variant_fk:mem.id,product_variant_group_fk:this.dndVariantGroups[i].id});
            });


            this.$store.dispatch('productvariant/saveMembersForProductvariantGroup', items)
            .then(response => {
              if(response.success == true)
              {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODGROUP.VAT_GROUP_MEM.SUCCESS.SAVE').replace("*1*",this.dndVariantGroups[i].bez)});  
              }
              else
              {
                var text = this.$t('PRODGROUP.VAT_GROUP_MEM.WARNING.SAVE').replace("*1*",this.$t(response.text));
                this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
              }
            }, 
            error =>{

                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODGROUP.VAT_GROUP_MEM.ERROR.SAVE').replace("*1*",error)});  
            });

            break;
          }
        }
      }
  }
};

</script>